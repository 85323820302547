import { ActionTypes } from "./actionType";

const initialState = {
  isActive: {
    id: "divOne",
  },
};

const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ORDER_TYPE:
      // console.log(action);
      return {
        ...state,
        isActive: action.payload,
      };
    default:
      return state;
  }
};

export default MainReducer;
