import { Link, Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import API from "../API/API";
import "../App.css";
import Spinner from "./Spinner";
import React from "react";
import Manage from "./ManagePO";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Manage PO Modal Popup
const Modal = ({ handleClose, id, setShow }) => {
  return (
    <div className="modal display-block">
      <section className="modal-main">
        <div className="App">
          <button className="popup-close" onClick={handleClose}>
            x
          </button>
          <Manage handleClose={handleClose} id={id} setShow={setShow} />
        </div>
      </section>
    </div>
  );
};

function ViewOrder() {
  const navigate = useNavigate();
  const { id, status } = useParams();
  const [orderData, setOrderData] = useState(orderDatainit);

  const [shippingMethodList, setShippingMethodList] = useState();
  const [loadDataViewOrder, setloadDataViewOrder] = useState(false); /// set a variable true to showcase the spinner
  const [loadDataShippingMethodList, setloadDataShippingMethodList] =
    useState(false);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState("");

  const [show, setShow] = useState(false);

  const [inputNotes, setInputNotes] = useState("");
  const [notesList, setNotesList] = useState([]);
  const hanldeClick = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  // Process Order
  const processedOrder = () => {
    setloadDataViewOrder(true);
    // console.log(selectedShippingMethod)
    // || selectedShippingMethod === "standard" || selectedShippingMethod === "nextDay" || selectedShippingMethod === "nextday" || selectedShippingMethod === "NextDayMorning" || selectedShippingMethod === "SecondDay"
    // if(selectedShippingMethod === "" ) {
    //   toast.warn("Select shipping method before to proceed.")
    //   console.log("shipping dataaaaaaaaaaaaa", {setSelectedShippingMethod})
    //   return
    // }

    const processData = {
      requestID: id,
    };

    API.processOrder(processData).then((response) => {
      getOrderDetails();
      setloadDataViewOrder(false); //// set the variable value related to spinner
      if (response?.status === 200) {
        toast.success(response?.message);
        navigate("/home");
      }
    });
  };

  const validInput = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
  );

  const getOrderDetails = async () => {
    setloadDataViewOrder(true);
    setloadDataShippingMethodList(true);

    API.viewOrderDetails(id, status).then((response) => {
      console.log("newwwwwwwwwwwwwwwwwwwwwwwww", response);
      setloadDataShippingMethodList(false);
      setloadDataViewOrder(false);

      setOrderData(response?.data);
      setNotesList(response?.data?.orderNotes);
      // console.log("initial array list", response?.data?.orderNotes)
      // setSelectedShippingMethod(response.data?.data?.entity?.extension_attributes?.shipping_assignments?.[0]?.shipping?.method)

      const dataPayload = {
        store_id: response.data?.data?.entity?.store_id,
        customer_group_id: response.data?.data?.entity?.customer_group_id,
        // order_total: response.data?.data?.entity?.subtotal,
        order_total: 0,
        order_id: Number(id),
        order_quantity: Number(response.data?.data?.entity?.total_qty_ordered),
        product_ids: response.data?.data?.entity?.items
          ?.map((item) => item.product_id)
          ?.toString(),
        address: {
          country:
            response.data?.data?.entity?.extension_attributes
              ?.shipping_assignments?.[0]?.shipping?.address?.country_id,
          region_id:
            response.data?.data?.entity?.extension_attributes
              ?.shipping_assignments?.[0]?.shipping?.address?.region_id,
          city: response.data?.data?.entity?.extension_attributes
            ?.shipping_assignments?.[0]?.shipping?.address?.city,
          zipcode:
            response.data?.data?.entity?.extension_attributes
              ?.shipping_assignments?.[0]?.shipping?.address?.postcode,
        },
      };

      const shippingMethod =
        response.data?.data?.entity?.extension_attributes
          ?.shipping_assignments?.[0]?.shipping?.method;

      API.shippingMethodDropdownList(dataPayload).then((response) => {
        setShippingMethodList(response?.data);
        response?.data?.map((item) => {
          if (item.code === shippingMethod) {
            setSelectedShippingMethod(item.code);
          }
        });


        setloadDataShippingMethodList(false); //// set the variable value related to spinner
      });
    });
  };

  // Add notes
  const handleAddNotes = async () => {
    setloadDataShippingMethodList(true);
    const notesData = {
      order_id: id,
      notes: inputNotes,
      created_by:
        localStorage.getItem("firstname") +
        " " +
        localStorage.getItem("lastname"),
    };
    API.notesAgainstPO(notesData).then((response) => {
      setloadDataShippingMethodList(false);
      toast.success(response?.message);

      if (response?.status == 200) {
        setInputNotes("");        
        setNotesList(response?.orderNotes) 
        // console.log("update array list", response?.orderNotes);
      }
    });
  };

  useEffect(() => {
    getOrderDetails();
    console.log(getOrderDetails())
  }, []);

  //Update Shipping Method Val
  const updateShippingMethodValList = (e) => {
    const param = {
      type: "shipping",
      order_id: id,
      cost: "",
      title: "",
      code: "",
    };
    let newid = "";

    shippingMethodList.map((item) => {
      // console.log(item)
      if (item.code === e.target.value) {
        newid = item.code;
        // console.log(item);
        param.cost = item.cost;
        param.title = item.title;
        param.code = item.code;
      }
    });

    API.updateShippingMethodVal(param).then((response) => {
      setSelectedShippingMethod(newid);
    });
  };

  return (
    <>
      <div className="outer-wrapper-pg pcodedc">
        <Spinner
          showSpinner={loadDataShippingMethodList || loadDataViewOrder}
        />{" "}
        {/* Assign props value  */}
        <div className="cus-page-heading">
          <h2 className="cus-pg-heading">PO Details</h2>
          <div className="cus-btn-group">
            {orderData?.status === "Need Attention" && (
              <div style={{ display: "inline-flex" }}>
                <button onClick={hanldeClick} className="btn delete-btn">
                  Manage PO
                </button>
                <button disabled
                  onClick={processedOrder}
                  className="btn gen-btn"
                  style={{ cursor: "auto" }}
                >
                  Process PO
                </button>
              </div>
            )}
            <Link to="/" className="btn cancel-btn">
              Back
            </Link>
          </div>
        </div>
        <div className="cus-inner-wrapper">
          <div className="container-fluid">
            <div className="row">
              <h5
                style={{
                  borderBottom: "solid 1px #ccc",
                  width: "100%",
                  paddingBottom: "10px",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                PO & Account Information{" "}
                <span className="cus-majento-number">
                  {orderData?.status === "processed" ? "#" + orderData?.estoreOrderId : ""}
                </span>
              </h5>
              <div className="" style={{ width: "50%", paddingRight: "50px" }}>
                <table className="table">
                  <tbody>
                    <tr className="cus-no-top-border cus-bottom-spc">
                      <td style={{ fontWeight: "600" }}>PO Number</td>
                      <td>
                        {orderData?.status === "processed"
                          ? orderData?.data?.entity?.po_reference
                          : orderData?.data?.entity?.po_reference}{" "}
                        <span
                          style={{
                            color: "green",
                            textTransform: "capitalize",
                          }}
                        >
                          ({orderData?.marketplace})
                        </span> &nbsp;  
                        <span className="cus-date-sec">{orderData?.data?.entity?.sent_date}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>PO Status</td>
                      <td
                        style={{
                          color: orderData?.status === "processed" ? "green" : "red",
                        }}
                      >
                        <span style={{ textTransform: "capitalize" }}>
                          {orderData?.status}
                        </span>
                      </td>
                    </tr>

                    {orderData?.message && (
                      <tr>
                        <td colSpan={2} style={{ backgroundColor: "#dedede" }}>
                          <strong style={{ fontWeight: "500" }}>
                            Additional Information:{" "}
                          </strong>
                          <div>
                            <i>{orderData?.message?.replaceAll("., ", ".\n")}</i>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="" style={{ width: "50%", paddingLeft: "50px" }}>
                <table className="table cus-acc-detail-sec">
                  <tbody>
                    <tr className="cus-no-top-border">
                      <td
                        className="cus-cai-sec"
                        colSpan="2"
                        style={{ fontWeight: "600" }}
                      >
                        Customer Account Information
                      </td>
                    </tr>
                    {(orderData?.marketplace == "Method") && (
                    <tr className="cus-bottom-spc">
                      <td colSpan={2}>
                      {
                          orderData?.data?.entity?.billing_address?.firstname
                        }
                        {" "}
                        {
                          orderData?.data?.entity?.billing_address?.lastname
                        }
                       ,{" "}
                        {
                          orderData?.data?.entity?.billing_address?.street
                        }
                        {" "}
                        {
                          orderData?.data?.entity?.billing_address?.city
                        }
                        ,{" "}
                        {
                          orderData?.data?.entity?.billing_address?.region_code
                        }
                        &nbsp;{" "}
                        {
                          orderData?.data?.entity?.billing_address?.postcode
                        }
                      </td>
                    </tr> )}
                    {(orderData?.marketplace == "Method") && (
                    <tr>
                      <td><b>Contact</b> </td>
                      <td>
                        : &nbsp; {orderData?.data?.entity?.billing_address?.firstname} &nbsp; {orderData?.data?.entity?.billing_address?.lastname}
                      </td>
                    </tr>
                    )}
                    <tr>
                      <td><b>Email</b>  </td>
                      <td>
                        : &nbsp; {orderData?.data?.entity?.customer_email}
                      </td>
                    </tr>
                    <tr>
                      <td><b>Phone</b>  </td>
                      <td>
                        : &nbsp;{" "}
                        {orderData?.data?.entity?.billing_address?.telephone}
                      </td>
                    </tr>
                    {(orderData?.marketplace == "Method") && ( 
                    <tr>
                      <td><b>Account #</b>  </td>
                      <td>
                        : &nbsp;{" "}
                        {orderData?.data?.entity?.account}
                      </td>
                    </tr> 
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <h5
                style={{
                  borderBottom: "solid 1px #ccc",
                  width: "100%",
                  paddingBottom: "10px",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                Items Ordered
              </h5>
              <div className="" style={{ width: "100%" }}>
                <table
                  className={orderData?.status ? orderData?.status : "table"}
                >
                  <thead>
                    <tr className="cus-no-top-border">
                      <th>SKU</th>
                      <th>Product</th>
                      <th style={{ textAlign: "right" }}>Price</th>
                      <th style={{ textAlign: "center" }}>Qty</th>
                      <th style={{ textAlign: "right" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData?.data?.entity?.items?.map((item) => {
                      return <ItemRecord data={item} status={orderData?.status} id={id} />;
                    })}

                    <tr className="cus-grandtotal-sec">
                      <td colSpan={4} style={{ textAlign: "right" }}>
                        <strong>Sub-Total</strong>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        ${orderData?.data?.entity?.subtotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <h5
                style={{
                  borderBottom: "solid 1px #ccc",
                  width: "100%",
                  paddingBottom: "10px",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                Account and Shipping Information
              </h5>
              <div className="cus-double-sec">
                <div className="cus-double-left-sec">
                  <h6>
                    <strong>PO Information</strong>
                  </h6>
                  <table>
                    <tr>
                      <td>PO Received</td>
                      <td>{orderData?.poReceivedOn}</td>
                    </tr>
                    <tr>
                      <td>PO Number</td>
                      <td>{orderData?.data?.entity?.po_reference}</td>
                    </tr>
                    <tr>
                      <td>Shipping Method</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {orderData?.old_shipping_method}
                      </td>
                    </tr>
                    <tr>
                      <td>Submitted By</td>
                      <td>{orderData?.data?.entity?.submitted_by}</td>
                    </tr>
                    <tr>
                      <td>Office Name</td>
                      <td>
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.firstname
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Office Address</td>
                      <td>
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.firstname
                        }
                        &nbsp;{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.lastname
                        }
                        -{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address?.street
                        }
                        &nbsp; ||{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address?.city
                        }
                        ,{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.region_code
                        }
                        &nbsp;{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.postcode
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Account #</td>
                      <td>{orderData?.data?.entity?.account}</td>
                    </tr>
                    <tr>
                      <td>Office Phone</td>
                      <td>
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.telephone
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Office Email </td>
                      <td>
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address?.email
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Affiliated DSO</td>
                      <td>{orderData?.data?.entity?.affiliated_dso}</td>
                    </tr>
                    <tr>
                      <td>Payment Method</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {orderData?.data?.entity?.payment?.method}
                      </td>
                    </tr>
                    {(orderData?.marketplace == "Method") && ( 
                    <tr>
                      <td>Payment Terms</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {orderData?.data?.entity?.payment_terms}
                      </td>
                    </tr>
                    )}
                    <tr>
                      <td>PO Note</td>
                      <td>{orderData?.data?.entity?.po_note}</td>
                    </tr>
                  </table>
                </div>
                <div className="cus-double-right-sec">
                  <h6>
                    <strong>In Magento</strong>
                  </h6>
                  <table>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>PO Number</td>
                      <td>{orderData?.data?.entity?.po_reference}</td>
                    </tr>
                    <tr>
                      <td>Shipping Methods</td>

                      <td>
                        {
                        // status === "success" && changes required just because status is not passing in the URL
                        orderData?.status === "processed" && 
                        (
                          <select
                            name="shipping-list"
                            id="shippinglists"
                            onChange={updateShippingMethodValList}
                            value={selectedShippingMethod}
                            disabled
                          >
                            <option value="">Select Shipping Method</option>
                            {shippingMethodList?.map((item) => {
                              return (
                                <option value={item.code}>{item.title}</option>
                              );
                            })}
                          </select>
                        )}
                        {
                        // status === "queue" && Changes because status is not passing in url anymore
                        orderData?.status === "Need Attention" && 
                        (
                          <select
                            name="shipping-list"
                            id="shippinglists"
                            onChange={updateShippingMethodValList}
                            value={selectedShippingMethod}
                          >
                            <option value="">Select Shipping Method</option>
                            {shippingMethodList?.map((item) => {
                              return (
                                <option value={item.code}>{item.title}</option>
                              );
                            })}
                          </select>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Submitted By</td>
                      <td>{orderData?.data?.entity?.submitted_by}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Ship To Address</td>
                      <td>
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.firstname
                        }
                        &nbsp;{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.lastname
                        }
                        -{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address?.street
                        }
                        &nbsp; ||{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address?.city
                        }
                        ,{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.region_code
                        }
                        &nbsp;{" "}
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.postcode
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Last Name</td>
                      <td>{orderData?.data?.entity?.account}</td>
                    </tr>
                    <tr>
                      <td>Ship To Phone #</td>
                      <td>
                        {
                          orderData?.data?.entity?.extension_attributes
                            ?.shipping_assignments[0]?.shipping?.address
                            ?.telephone
                        }
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Customer Group </td>
                      <td>{orderData?.data?.entity?.affiliated_dso}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    {(orderData?.marketplace == "Method") && ( <tr>
                      <td></td>
                      <td></td>
                    </tr>  )}
                    <tr>
                     
                      <td></td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <h5
                style={{
                  borderBottom: "solid 1px #ccc",
                  width: "100%",
                  paddingBottom: "10px",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                Notes for this PO
              </h5>
              <div className="cus-notes-sec">
                {/* {status === "queue" && ( */}
                  <div className="form-group" style={{ marginTop: "1rem" }}>
                    <textarea
                      rows="4"
                      cols="85"
                      placeholder="Enter notes..."
                      value={inputNotes}
                      // pattern="[A-Za-z0-9]{1,20}"
                      onChange={(e) => setInputNotes(e.target.value)}
                      // onChange={(e) => {
                      //   if(validInput.test(e.target.value)){
                      //     setInputNotes(e.target.value)
                      //   } else{
                      //     setInputNotes("")
                      //   }
                        
                      // }}
                    ></textarea>

                    <button
                      className="actionbtn"
                      disabled={!inputNotes.trim().length}
                      onClick={handleAddNotes}
                    >
                      Submit{" "}
                    </button>
                  </div> 
                {/* )} */}
                <div className="cus-list-notes">                 
                  <ul>
                    {notesList?.map((item) => {
                      return (
                        <li>
                          <div className="notes-list">{item?.Notes}</div>
                          <div className="notes-list-footer">
                            <div className="notes-list-left-footer">
                              By: <span>{item?.CreatedBy}</span>
                            </div>
                            <div className="notes-list-right-footer">
                              On: <span>{item?.CreatedOn}</span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <Modal handleClose={hideModal} id={id} setShow={setShow} />}
    </>
  );
}

export default ViewOrder;

const orderDatainit = {
  data: {
    entity: {
      order_id: "",
      base_currency_code: "",
      base_discount_amount: 0,
      base_grand_total: 0,
      base_shipping_amount: 0,
      base_subtotal: 0,
      base_tax_amount: 0,
      customer_email: "",
      customer_firstname: "",
      customer_lastname: "",
      discount_amount: 0,
      grand_total: 0,
      is_virtual: 0,
      order_currency_code: "",
      shipping_amount: 0,
      shipping_description: "",
      state: "",
      status: "",
      store_currency_code: "",
      subtotal: 0,
      subtotal_incl_tax: 0,
      tax_amount: 0,
      total_item_count: 0,
      total_qty_ordered: 0,
      weight: 1,

      items: [],

      billing_address: {
        address_type: "",

        city: "",

        country_id: "",

        email: "",

        firstname: "",

        lastname: "",

        postcode: "",

        region: "",

        region_code: "",

        region_id: 0,

        street: [],

        telephone: "",
      },

      payment: {
        method: "",
      },

      extension_attributes: {
        shipping_assignments: [],
      },
    },
  },
  marketplace: "",
  estore: "",
  status: "",
  marketplace_order_id: "",
  message: "",
};

const ItemRecord = (props) => {
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [skuText, setSkuText] = useState(props.data?.sku);
  const [skuList, setSkuList] = useState([]);
  const [productName, setProductName] = useState(props.data?.name);

  const [loadData, setLoadData] = useState(true); /// set a variable true to showcase the spinner
  // Search value for SKU dropdown
  const searchSKUCall = () => {
    API.searchSKU(props.data?.sku).then((response) => {
      setSkuList(response?.data);
      setLoadData(false);
    });
  };

  // SKU Dropdown
  const handleChange = (ev) => {
    setProductName(skuList[ev.target.value].name);
    setSkuText(skuList[ev.target.value].sku);
    setLoadData(false);
  };

  // Process Order Call
  const updateOrderCall = () => {
    if (skuText === props.data?.sku) {
      alert("You are trying to update SKU with the same existing value.");
      return;
    }
    const payload = {
      column_type: "need_attention",
      type: "sku",
      order_id: props?.id,
      old_sku: props.data?.sku,
      sku: skuText,
      name: productName,
    };

    API.updateSKUandProduct(payload).then((response) => {
      setEditingEnabled(false);
      setLoadData(false); //// set the variable value related to spinner
    });
    
  };

  //Get Shipping Method List
  const shippingMethodDropdownList = () => {
    const shippingListData = {
      store_id: "",
      customer_group_id: "",
      order_total: "",
      order_quantity: "",
      product_ids: "",
      country: "",
      region_id: "",
      city: "",
      zipcode: "",
    };

    API.shippingMethodDropdownList(shippingListData).then((response) => {
      setEditingEnabled(false);
    });
  };

  return (
    <>
      <tr className="cus-editable-field-sec">
        {!editingEnabled && (
          <td className="cus-editable-sec">
            {skuText}{" "}
            {props.data?.sku_edit && props.status === "Need Attention" && (
              <aside className="cus-red-flag">!</aside>
            )}{" "}
          </td>
        )}
        {editingEnabled && (
          <td className="cus-editable-sec">
            {/* <input onChange={(e) => setSkuText(e.target.value)} value={skuText} />  */}
            <select onChange={handleChange} name="skuList">
              <option value="">Select SKU</option>
              {skuList?.map((item, index) => (
                <option value={index}>{item.sku}</option>
              ))}
            </select>
          </td>
        )}
        {
        props.status === "Need Attention" && 
        // props.orderData.status === "Need Attention" && 
        (
          <span className="cus-edit-action-sec">
            {editingEnabled && (
              <div>
                <button onClick={updateOrderCall}>
                  <i className="ti-check"></i>
                </button>
                <button onClick={() => setEditingEnabled(false)}>
                  <i className="ti-close"></i>
                </button>
              </div>
            )}
            {props.data?.sku_edit && !editingEnabled && (
              <span>
                {" "}
                <button
                  onClick={() => {
                    setEditingEnabled(!editingEnabled);
                    searchSKUCall();
                  }}
                >
                  {" "}
                  <i className="ti-pencil"></i>
                </button>
              </span>
            )}
          </span>
        )}
        {
        props.status === "processed" && 
        // props.orderData.status === "processed" && 
        
        (
          <span className="cus-edit-action-sec"> </span>
        )}

        <td>{productName} </td>
        <td style={{ textAlign: "right" }}>${props.data?.price}</td>
        <td style={{ textAlign: "center" }}>{props.data?.qty_ordered}</td>
        <td style={{ textAlign: "right" }}>${props.data?.row_total}</td>
      </tr>
    </>
  );
};

const entity = {
  base_currency_code: "USD",
  base_discount_amount: 50.5,
  base_grand_total: 233.63,
  base_shipping_amount: 15.44,
  base_subtotal: 244.26,
  base_tax_amount: "",
  customer_email: "jamesl@localhost.com",
  customer_firstname: "",
  customer_lastname: "",
  customer_group_id: 0,
  customer_id: 0,
  customer_is_guest: 0,
  customer_note_notify: 0,
  discount_amount: 50.5,
  email_sent: 0,
  coupon_code: "",
  discount_description: "",
  grand_total: 233.63,
  is_virtual: 0,
  order_currency_code: "USD",
  shipping_amount: 15.44,
  shipping_description: "",
  state: "new",
  status: "new",
  store_currency_code: "USD",
  store_id: 0,
  store_name: "",
  subtotal: 244.26,
  subtotal_incl_tax: "",
  tax_amount: "",
  total_item_count: "",
  total_qty_ordered: "",
  weight: "",
  po_reference: "ZEST100045",
  items: [
    {
      sku: "08505-SB",
      qty_ordered: 2,
      price: 86.99,
      base_discount_amount: 0,
      base_original_price: 0,
      base_price: 86.99,
      base_price_incl_tax: 0,
      base_row_invoiced: 0,
      base_row_total: 0,
      base_tax_amount: 0,
      base_tax_invoiced: 0,
      discount_amount: 0,
      discount_percent: 0,
      free_shipping: 0,
      is_virtual: 0,
      name: "LOCATOR Impression Coping (4 Pack)",
      original_price: 86.99,
      price_incl_tax: 0,
      product_id: 2530,
      product_type: "simple",
      row_total: "173.98",
      row_total_incl_tax: 0,
      store_id: 0,
      tax_class_id: "9",
    },
    {
      sku: "08505-20-SB",
      qty_ordered: 1,
      price: 382.99,
      base_discount_amount: 0,
      base_original_price: 0,
      base_price: 382.99,
      base_price_incl_tax: 0,
      base_row_invoiced: 0,
      base_row_total: 0,
      base_tax_amount: 0,
      base_tax_invoiced: 0,
      discount_amount: 0,
      discount_percent: 0,
      free_shipping: 0,
      is_virtual: 0,
      name: "LOCATOR Impression Coping (20 Pack)",
      original_price: 382.99,
      price_incl_tax: 0,
      product_id: 2531,
      product_type: "simple",
      row_total: "382.99",
      row_total_incl_tax: 0,
      store_id: 0,
      tax_class_id: "9",
    },
  ],
  billing_address: {
    address_type: "",
    city: "HOUSTON",
    company: "",
    country_id: "US",
    email: "arnab@dentira.com",
    firstname: "MB2 Dental Corporate",
    lastname: "",
    postcode: "23354",
    region: "",
    region_code: "TX",
    region_id: "",
    street: "84 FS Ste E",
    telephone: "(837) 612-1377",
  },
  payment: {
    method: "BillMyAccount",
  },
  extension_attributes: {
    shipping_assignments: [
      {
        shipping: {
          address: {
            address_type: "",
            city: "Austin",
            company: "",
            country_id: "US",
            email: "jamespaul@localhost.com",
            firstname: "James Paul",
            lastname: "",
            postcode: "72424",
            region: "",
            region_code: "TX",
            region_id: "",
            street: "James Home 2",
            telephone: "4532452166",
          },
          method: "Standard",
        },
      },
    ],
  },
  account: 76256,
  affiliated_dso: "Test DSO",
  submitted_by: "James",
  po_note: "This is a test order for james",
};
