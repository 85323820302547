import axios from "axios";
import baseURLObj from "./Common/AppUrl";


export const refreshToken = async () => {
  try {
    let refreshToken = localStorage.getItem("refreshToken");
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const newTokenResponse = await axios.post(
      baseURLObj.baseUrl + "/rest/api/v1/admin/refreshtoken/index.php",
      {
        refresh_token: refreshToken,
      },{}
    );

    if (newTokenResponse && newTokenResponse.data.status === 200) {
      let newToken = newTokenResponse.data.access_token;
      let newTokenExp = newTokenResponse.data.exp_timestamp_utc;
      let newRefreshTokenExp = newTokenResponse.data.refresh_token;

      // Update the token and expiration time in localStorage
      console.log("token refresh");
      console.log(newTokenResponse.data)
      localStorage.setItem("token", newToken);
      localStorage.setItem("tokenExp", JSON.stringify(newTokenExp));
      localStorage.setItem("refreshToken",newRefreshTokenExp);
    } else {
      // Handle refresh token error
      console.error("Error refreshing token");
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
};
