import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../Redux/actionType";
import Spinner from "./Spinner";
import baseURLObj from "../Common/AppUrl";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import NotesIcon from "../Assets/Images/comment.png";
import TickIcon from "../Assets/Images/check-mark.png";
import API from "../API/API";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import HoverListRefresh from "../Assets/Images/refresh_hover.svg";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import * as XLSX from "xlsx";
import filterFactory, {
  textFilter,
  selectFilter,
  customFilter,
} from "react-bootstrap-table2-filter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import sortingImg from "../Assets/Images/sorting-arrow.png";
// import sortingImgAsc from "../Assets/Images/sorting-arrow-asc.png";
// import sortingImgDsc from "../Assets/Images/sorting-arrow-dsc.png";
// import ListRefresh from "../Assets/Images/refresh.svg";
// import { $ } from "react-jquery-plugin";
// import { DateRangePicker } from 'react-date-range';
// import DateRangeInput from "rsuite/DateRangeInput";
// import moment from "moment";
// import Moment from "react-moment";
// import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
// import ToolkitProvider from "react-bootstrap-table2-toolkit";

let resetFilterAllOrders = false;
let resetFilterProcessOrders = false;
let resetFilterNeedAttention = false;
let resetFilterRejected = false;

let statusFilter;
let poNumberFilter;
let customerEmailFilter;
let orderIdFilter;
let shippingFilter;
let sourceFilter;

const selectStatus = {
  0: "All",
  1: "Attention",
  2: "Processed",
  3: "Rejected",
};

const selectShipping = {
  0: "Standard",
  1: "Second Day",
  2: "Next Day Morning",
  3: "Next Day",
};

const selectSource = {
  0: "Method",
  1: "Dentira",
};

let poNumberFilter2;
let customerEmailFilter2;
let shippingFilter2;
let sourceFilter2;
let orderIdFilter2;

let poNumberFilter3;
let customerEmailFilter3;
let shippingFilter3;
let sourceFilter3;

let poNumberFilter4;
let customerEmailFilter4;
let shippingFilter4;
let sourceFilter4;

let stDate;
let edDate;

export const DateRangeFilter = ({
  column,
  onFilter,
  filterValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ...rest
}) => {
  const applyFilter = () => {
    stDate = startDate.toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    edDate = endDate.toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    onFilter({
      startDate: stDate,
      endDate: edDate,
    });
  };

  const handleStartDateChange = (date) => {
    // Reset End Date only when it is smaller than Start Date
    if (endDate && date > endDate) {
      setEndDate(null);
    }
    setStartDate(date);
  };

  const minAllowedDate = new Date();

  minAllowedDate.setDate(minAllowedDate.getDate() - 60); // Set to 60 days earlier

  const handleSortingEvent = (e) => {
    if (e && e.stopPropagation) e.stopPropagation();
  };

  const isApplyButtonDisabled = !startDate || !endDate;

  return (
    <div className="daterange-sec" onClick={(e) => handleSortingEvent(e)}>
      <DatePicker
        selected={startDate}
        // onClick={handleClickStartInput}
        onChange={(date) => handleStartDateChange(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="From Date*"
        maxDate={new Date()} // Disable dates after the current date
        minDate={minAllowedDate}
      />
      <DatePicker
        selected={endDate}
        // onClick={handleClickEndInput}
        // onChange={(date) => {handleEndDateChange(date), setEndDate(date)}}
        onChange={(date) => {
          setEndDate(date);
        }}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="To Date*"
        maxDate={new Date()} // Disable dates after the current date
        disabled={!startDate}
      />
      <button
        data-testid="div-apply-button"
        name="ApplyButton"
        onClick={applyFilter}
        disabled={isApplyButtonDisabled}
      >
        <img className="tick-icon" src={TickIcon} />
      </button>
    </div>
  );
};

const Content = ({ onLogout }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { id } = useParams();
  const [showListPopup, setShowListPopup] = useState(false);
  const hanldeOpenNotesListPopup = () => {
    setShowListPopup(true);
  };
  const hideModal = () => {
    setShowListPopup(false);
  };

  const [count, setCount] = useState([]);
  const [queueData, setQueueData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const [deletedData, setDeletedData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true); /// set a variable true to showcase the spinner

  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.MainData.isActive);

  const [currPageNumber, setCurrPageNumber] = useState(1);
  const [totalDataPagination, setTotalDataPagination] = useState("");
  const [sizePerPage, setSizePerPage] = useState(50);
  const [currPageNumberSuccess, setCurrPageNumberSuccess] = useState(1);
  const [successDataPagination, setSuccessDataPagination] = useState("");
  const [sizePerPageSuccess, setSizePerPageSuccess] = useState(50);
  const [currPageNumberAttention, setCurrPageNumberAttention] = useState(1);
  const [attentionDataPagination, setAttentionDataPagination] = useState("");
  const [sizePerPageAttention, setSizePerPageAttention] = useState(50);
  const [currPageNumberDeleted, setCurrPageNumberDeleted] = useState(1);
  const [deletedDataPagination, setDeletedDataPagination] = useState("");
  const [sizePerPageDeleted, setSizePerPageDeleted] = useState(50);

  const [orderNotesPopup, setOrderNotesPopup] = useState([]);

  function viewPO(cell, row) {
    return (
      <>
        <button
          className="btn btn-primary btn-round"
          onClick={() => {
            navigate(`/po/${row.id}`);
          }}
        >
          View PO
        </button>
      </>
    );
  }

  function viewNotes(cell, row) {
    return (
      <>
        {row?.orderNotesCount > 0 && (
          <button
            className="notes-sec"
            onClick={() => {
              hanldeOpenNotesListPopup();
              setOrderNotesPopup(row.orderNotes);
            }}
          >
            <img className="notes-icon" src={NotesIcon} />
            <sup>
              {row?.orderNotesCount}
              {row?.orderNotesCount > 99 && <small>+</small>}
            </sup>
          </button>
        )}
      </>
    );
  }

  function viewStatus(cell, row) {
    return (
      <>
        <div className="status-button-container">
          <button
            className={`btn btn-round status smll ${
              row.status === "processed" ? "bg-c-green" : "bg-c-pink"
            }`}
            title={
              row.status === "Need Attention" && row.message ? row.message : ""
            }
          >
            <span className="st-btn">
              {row.status === "Need Attention" ? "Attention" : row.status}
            </span>
          </button>
          {row.status !== "processed" && row.message && (
            <i className="ti-info-alt exclamation-icon" title={row.message} />
          )}
        </div>
      </>
    );
  }

  function datePickerFunction(onFilter, column, columnIndex) {
    return (
      <DateRangeFilter
        column={column}
        onFilter={onFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    );
  }

  const allPoDataTableColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: viewStatus,
      sort: true,
      // formatter: (cell) => selectStatus[cell],
      filter: selectFilter({
        options: selectStatus,
        getFilter: (filter) => {
          statusFilter = filter;
        },
      }),
    },
    {
      dataField: "poReference",
      text: "PO Number",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          poNumberFilter = filter;
        },
      }),
    },
    {
      dataField: "poReceivedOn",
      text: "PO Received",
      sort: true,
      // formatter: stopSortingM,
      filter: customFilter(),
      filterRenderer: datePickerFunction,
    },
    {
      dataField: "customerEmail",
      text: "Customer Email",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          customerEmailFilter = filter;
        },
      }),
    },
    {
      dataField: "shippingMethod",
      text: "Shipping Method",
      sort: true,
      filter: selectFilter({
        options: selectShipping,
        getFilter: (filter) => {
          shippingFilter = filter;
        },
      }),
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      // formatter: cell => selectSource[cell],
      filter: selectFilter({
        options: selectSource,
        getFilter: (filter) => {
          sourceFilter = filter;
        },
      }),
    },
    {
      dataField: "estoreOrderId",
      text: "Order ID",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          orderIdFilter = filter;
        },
      }),
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: viewPO,
    },
    {
      dataField: "orderNotesCount",
      text: "Notes",
      formatter: viewNotes,
    },
  ];

  const successPoTableColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: viewStatus,
    },
    {
      dataField: "poReference",
      text: "PO Number",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          poNumberFilter2 = filter;
        },
      }),
    },
    {
      dataField: "poReceivedOn",
      text: "PO Received",
      sort: true,
      filter: customFilter(),
      filterRenderer: datePickerFunction,
    },
    {
      dataField: "customerEmail",
      text: "Customer Email",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          customerEmailFilter2 = filter;
        },
      }),
    },
    {
      dataField: "shippingMethod",
      text: "Shipping Method",
      sort: true,
      filter: selectFilter({
        options: selectShipping,
        getFilter: (filter) => {
          shippingFilter2 = filter;
        },
      }),
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      filter: selectFilter({
        options: selectSource,
        getFilter: (filter) => {
          sourceFilter2 = filter;
        },
      }),
    },
    {
      dataField: "estoreOrderId",
      text: "Order ID",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          orderIdFilter2 = filter;
        },
      }),
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: viewPO,
    },
    {
      dataField: "notes",
      text: "Notes",
      formatter: viewNotes,
    },
  ];

  const needAttentionPoTableColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: viewStatus,
    },
    {
      dataField: "poReference",
      text: "PO Number",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          poNumberFilter3 = filter;
        },
      }),
    },
    {
      dataField: "poReceivedOn",
      text: "PO Received",
      sort: true,
      filter: customFilter(),
      filterRenderer: datePickerFunction,
    },
    {
      dataField: "customerEmail",
      text: "Customer Email",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          customerEmailFilter3 = filter;
        },
      }),
    },
    {
      dataField: "shippingMethod",
      text: "Shipping Method",
      filter: selectFilter({
        options: selectShipping,
        getFilter: (filter) => {
          shippingFilter3 = filter;
        },
      }),
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      filter: selectFilter({
        options: selectSource,
        getFilter: (filter) => {
          sourceFilter3 = filter;
        },
      }),
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: viewPO,
    },
    {
      dataField: "notes",
      text: "Notes",
      formatter: viewNotes,
    },
  ];

  const rejectedPoTableColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: viewStatus,
    },
    {
      dataField: "poReference",
      text: "PO Number",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          poNumberFilter4 = filter;
        },
      }),
    },
    {
      dataField: "poReceivedOn",
      text: "PO Received",
      sort: true,
      filter: customFilter(),
      filterRenderer: datePickerFunction,
    },
    {
      dataField: "customerEmail",
      text: "Customer Email",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          customerEmailFilter4 = filter;
        },
      }),
    },
    {
      dataField: "shippingMethod",
      text: "Shipping Method",
      filter: selectFilter({
        options: selectShipping,
        getFilter: (filter) => {
          shippingFilter4 = filter;
        },
      }),
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      filter: selectFilter({
        options: selectSource,
        getFilter: (filter) => {
          sourceFilter4 = filter;
        },
      }),
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: viewPO,
    },
    {
      dataField: "notes",
      text: "Notes",
      formatter: viewNotes,
    },
  ];

  const hideShowDiv = (id) => {
    // setIsActive({
    //   id: id,
    // }) // Set the ID of the active div
    dispatch({
      type: ActionTypes.ORDER_TYPE,
      payload: {
        id: id,
      },
    });
  };

  const handleLogout = () => {
    onLogout(); // Call the onLogout function passed from App.js
    navigate("/"); // Redirect to login after logout
  };

  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  const fetchQueueList = async (pageNumber, sizeOfPage, filters) => {
    let url = baseURLObj.baseUrl + "/rest/api/v1/orders/total/index.php";

    if (!pageNumber) {
      pageNumber = 1;
    }

    if (!sizeOfPage) {
      sizeOfPage = 50;
    }

    url += "?page=" + pageNumber + "&pageSize=" + sizeOfPage;

    if (filters) {
      for (const property in filters) {
        switch (property) {
          case "status":
            let stat = selectStatus[filters["status"].filterVal];
            if (stat == "Attention") {
              stat = "need_attention";
            } else if (stat == "All") {
              stat = "all";
            }
            url += "&status=" + stat;
            break;
          case "poReference":
            let poref = filters["poReference"].filterVal;
            url += "&poReference=" + poref;
            break;
          case "customerEmail":
            let custEmail = filters["customerEmail"].filterVal;
            url += "&email=" + custEmail;
            break;
          case "shippingMethod":
            let ship = selectShipping[filters["shippingMethod"].filterVal];
            url += "&shippingMethod=" + camelize(ship);
            break;
          case "source":
            let sour = selectSource[filters["source"].filterVal];
            url += "&source=" + camelize(sour);
            break;
          case "estoreOrderId":
            let ordrId = filters["estoreOrderId"].filterVal;
            url += "&orderId=" + ordrId;
            break;
          case "poReceivedOn":
            if (startDate !== null && endDate !== null) {
              url +=
                "&poReceivedStartDate=" +
                moment(startDate).format("YYYY-MM-DD") +
                "&poReceivedEndDate=" +
                moment(endDate).format("YYYY-MM-DD");
            }
            break;
        }
      }
    }

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const fetchTotalData = axios.get(
      url, // All record listing API
      {
        headers: headers,
      }
    );

    setLoadData(true);
    fetchTotalData
      .then((response) => {
        const totalDataResponse = response;
        setTotalData(totalDataResponse?.data?.data);
        setTotalDataPagination(totalDataResponse?.data?.pagination);
        setLoadData(false); //// set the variable value related to spinner
      })

      .catch((error) => {
        setLoadData(false); //// set the variable value related to spinner
        handleLogout();
      });
  };

  /// Success Tab Page Data
  const fetchSuccessList = async (pageNumber, sizeOfPage, filters) => {
    let url = baseURLObj.baseUrl + "/rest/api/v1/orders/success/index.php";

    if (!pageNumber) {
      pageNumber = 1;
    }

    if (!sizeOfPage) {
      sizeOfPage = 50;
    }

    url += "?page=" + pageNumber + "&pageSize=" + sizeOfPage;

    if (filters) {
      for (const property in filters) {
        switch (property) {
          case "poReference":
            let poref = filters["poReference"].filterVal;
            url += "&poReference=" + poref;
            break;
          case "poReceivedOn":
            if (startDate !== null && endDate !== null) {
              url +=
                "&poReceivedStartDate=" +
                moment(startDate).format("YYYY-MM-DD") +
                "&poReceivedEndDate=" +
                moment(endDate).format("YYYY-MM-DD");
            }
            break;
          case "customerEmail":
            let custEmail = filters["customerEmail"].filterVal;
            url += "&email=" + custEmail;
            break;
          case "shippingMethod":
            let ship = selectShipping[filters["shippingMethod"].filterVal];
            url += "&shippingMethod=" + camelize(ship);
            break;
          case "source":
            let sour = selectSource[filters["source"].filterVal];
            url += "&source=" + camelize(sour);
            break;
          case "estoreOrderId":
            let ordrId = filters["estoreOrderId"].filterVal;
            url += "&orderId=" + ordrId;
            break;
        }
      }
    }

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const fetchSuccessData = axios.get(
      url, // All record listing API
      {
        headers: headers,
      }
    );

    fetchSuccessData
      .then((response) => {
        const successDataResponse = response;
        setSuccessData(successDataResponse?.data?.data);
        setSuccessDataPagination(successDataResponse?.data?.pagination);
        setLoadData(false); //// set the variable value related to spinner
      })

      .catch((error) => {
        setLoadData(false); //// set the variable value related to spinner
        handleLogout();
      });
  };

  /// Need Attention Tab Page Data
  const fetchAttentionList = async (pageNumber, sizeOfPage, filters) => {
    let url = baseURLObj.baseUrl + "/rest/api/v1/orders/queue/index.php";

    if (!pageNumber) {
      pageNumber = 1;
    }

    if (!sizeOfPage) {
      sizeOfPage = 50;
    }

    url += "?page=" + pageNumber + "&pageSize=" + sizeOfPage;

    if (filters) {
      for (const property in filters) {
        switch (property) {
          case "poReference":
            let poref = filters["poReference"].filterVal;
            url += "&poReference=" + poref;
            break;
          case "poReceivedOn":
            if (startDate !== null && endDate !== null) {
              url +=
                "&poReceivedStartDate=" +
                moment(startDate).format("YYYY-MM-DD") +
                "&poReceivedEndDate=" +
                moment(endDate).format("YYYY-MM-DD");
            }
            break;
          case "customerEmail":
            let custEmail = filters["customerEmail"].filterVal;
            url += "&email=" + custEmail;
            break;
          case "shippingMethod":
            let ship = selectShipping[filters["shippingMethod"].filterVal];
            url += "&shippingMethod=" + camelize(ship);
            break;
          case "source":
            let sour = selectSource[filters["source"].filterVal];
            url += "&source=" + camelize(sour);
            break;
        }
      }
    }

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const fetchQueueData = axios.get(
      url, // All record listing API
      {
        headers: headers,
      }
    );

    fetchQueueData
      .then((response) => {
        const attentionDataResponse = response;
        setQueueData(attentionDataResponse?.data?.data);
        setAttentionDataPagination(attentionDataResponse?.data?.pagination);
        setLoadData(false); //// set the variable value related to spinner
      })

      .catch((error) => {
        setLoadData(false); //// set the variable value related to spinner
        handleLogout();
      });
  };

  /// Rejected Tab Page Data
  const fetchDeletedList = async (pageNumber, sizeOfPage, filters) => {
    let url = baseURLObj.baseUrl + "/rest/api/v1/orders/rejected/index.php";

    if (!pageNumber) {
      pageNumber = 1;
    }

    if (!sizeOfPage) {
      sizeOfPage = 50;
    }

    url += "?page=" + pageNumber + "&pageSize=" + sizeOfPage;

    if (filters) {
      for (const property in filters) {
        switch (property) {
          case "poReference":
            let poref = filters["poReference"].filterVal;
            url += "&poReference=" + poref;
            break;
          case "poReceivedOn":
            if (startDate !== null && endDate !== null) {
              url +=
                "&poReceivedStartDate=" +
                moment(startDate).format("YYYY-MM-DD") +
                "&poReceivedEndDate=" +
                moment(endDate).format("YYYY-MM-DD");
            }
            break;
          case "customerEmail":
            let custEmail = filters["customerEmail"].filterVal;
            url += "&email=" + custEmail;
            break;
          case "shippingMethod":
            let ship = selectShipping[filters["shippingMethod"].filterVal];
            url += "&shippingMethod=" + camelize(ship);
            break;
          case "source":
            let sour = selectSource[filters["source"].filterVal];
            url += "&source=" + camelize(sour);
            break;
        }
      }
    }

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const fetchDeletedData = axios.get(url, {
      headers: headers,
    });

    fetchDeletedData
      .then((response) => {
        const deletedDataResponse = response;
        setDeletedData(deletedDataResponse?.data?.data);
        setDeletedDataPagination(deletedDataResponse?.data?.pagination);
        setLoadData(false);
      })

      .catch((error) => {
        setLoadData(false);
      });
  };

  const resetAllColumnFilter = () => {
    resetFilterAllOrders = true;

    statusFilter("");
    poNumberFilter("");
    customerEmailFilter("");
    orderIdFilter("");
    shippingFilter("");
    sourceFilter("");
    setStartDate(null);
    setEndDate(null);

    resetFilterProcessOrders = true;
    poNumberFilter2("");
    customerEmailFilter2("");
    shippingFilter2("");
    sourceFilter2("");
    orderIdFilter2("");

    resetFilterNeedAttention = true;
    poNumberFilter3("");
    customerEmailFilter3("");
    shippingFilter3("");
    sourceFilter3("");

    resetFilterRejected = true;
    poNumberFilter4("");
    customerEmailFilter4("");
    shippingFilter4("");
    sourceFilter4("");
  };

  const updateOrderCountAndFetchList = async (type = "divOne") => {
    setLoadData(true);

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const fetchCount = axios.get(
      baseURLObj.baseUrl + "/rest/api/v1/orders/count/index.php", //No. of record count in tab API
      {
        headers: headers,
      }
    );

    const timeout = setTimeout(() => {
      setLoadData(false); // Set the variable value related to spinner
      setShowModal(true);
    }, 120000); // 120 seconds

    if (type == "divOne") {
      setCurrPageNumber(1);
      setSizePerPage(50);

      const fetchTotalData = axios.get(
        baseURLObj.baseUrl + "/rest/api/v1/orders/total/index.php", // All record listing API
        {
          headers: headers,
        }
      );

      Promise.all([fetchCount, fetchTotalData])

        .then((responses) => {
          const countResponse = responses[0];
          const totalDataResponse = responses[1];

          setCount(countResponse.data.data);
          setTotalData(totalDataResponse.data.data);
          setTotalDataPagination(totalDataResponse?.data?.pagination);

          setLoadData(false); //// set the variable value related to spinner
          resetFilterAllOrders = false;

          clearTimeout(timeout); // Clear the timeout if data arrives before 120 seconds
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadData(false); //// set the variable value related to spinner
          setShowModal(true); // Show modal on error
          handleLogout();
        });

    } 
    else if (type == "divTwo") {

      setCurrPageNumberSuccess(1);
      setSizePerPageSuccess(50);

      const fetchSuccessData = axios.get(
        baseURLObj.baseUrl + "/rest/api/v1/orders/success/index.php", // Success record listing API
        {
          headers: headers,
        }
      );

      Promise.all([fetchCount, fetchSuccessData])

        .then((responses) => {
          const countResponse = responses[0];
          const successDataResponse = responses[1];
          setCount(countResponse.data.data);

          setSuccessData(successDataResponse.data.data);
          setSuccessDataPagination(successDataResponse?.data?.pagination);

          setLoadData(false); //// set the variable value related to spinner

          resetFilterProcessOrders = false;

          clearTimeout(timeout); // Clear the timeout if data arrives before 120 seconds
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadData(false); //// set the variable value related to spinner
          setShowModal(true); // Show modal on error
          handleLogout();
        });
    } 
    else if (type == "divThree") {
      setCurrPageNumberAttention(1);
      setSizePerPageAttention(50);
      const fetchQueueData = axios.get(
        baseURLObj.baseUrl + "/rest/api/v1/orders/queue/index.php", // Need Attention record listing API
        {
          headers: headers,
        }
      );

      Promise.all([fetchCount, fetchQueueData])

        .then((responses) => {
          const countResponse = responses[0];
          const queueDataResponse = responses[1];

          setCount(countResponse.data.data);
          setQueueData(queueDataResponse.data.data); // Adjust this depending on the response format
          setAttentionDataPagination(queueDataResponse?.data?.pagination);

          setLoadData(false); //// set the variable value related to spinner
          resetFilterNeedAttention = false;

          clearTimeout(timeout); // Clear the timeout if data arrives before 120 seconds
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadData(false); //// set the variable value related to spinner
          setShowModal(true); // Show modal on error
          handleLogout();
        });
    } 
    else {
      setCurrPageNumberDeleted(1);
      setSizePerPageDeleted(50);

      const fetchDeletedData = axios.get(
        baseURLObj.baseUrl + "/rest/api/v1/orders/rejected/index.php", // Reject/Delete record listing API
        {
          headers: headers,
        }
      );

      Promise.all([fetchCount, fetchDeletedData])

        .then((responses) => {
          const countResponse = responses[0];
          const deletedDataResponse = responses[1];
          setCount(countResponse.data.data);

          setDeletedData(deletedDataResponse.data.data);
          setDeletedDataPagination(deletedDataResponse?.data?.pagination);
          setLoadData(false); //// set the variable value related to spinner

          resetFilterRejected = false;
          clearTimeout(timeout); // Clear the timeout if data arrives before 120 seconds
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadData(false); //// set the variable value related to spinner
          setShowModal(true); // Show modal on error
          handleLogout();
        });
    }

  };

  useEffect(() => {
    document.title = "SupplyLink: Orders";
    updateOrderCountAndFetchList(isActive.id);
   
    // Call All list API automatically after every 30min.
    // const interval = setInterval(() => {
    //   updateOrderCountAndFetchList();
    // }, 1800000);
    // return () => clearInterval(interval);
  }, []);

  const allPORecordsToExcel = () => {
    setLoadData(true);
    API.exportTableAllPOToExcel().then((response) => {
      setLoadData(false);
      handleAllPODownload(response?.data);

      if (response?.status === 200) {
        toast.success(
          "Data will be exported for last week only. For exporting data beyond that, please reach out to the support team."
        );
      }
    });
  };

  const successRecordsToExcel = () => {
    setLoadData(true);
    API.exportTableSuccessToExcel().then((response) => {
      setLoadData(false);
      handleSuccessDownload(response?.data);
      if (response?.status === 200) {
        toast.success(
          "Data will be exported for last week only. For exporting data beyond that, please reach out to the support team."
        );
      }
    });
  };

  const needAttentionRecordsToExcel = () => {
    setLoadData(true);
    API.exportTableNeedAttentionToExcel().then((response) => {
      setLoadData(false);
      handleNeedAttentionDownload(response?.data);
      if (response?.status === 200) {
        toast.success(
          "Data will be exported for last week only. For exporting data beyond that, please reach out to the support team."
        );
      }
    });
  };

  const rejectedRecordsToExcel = () => {
    setLoadData(true);
    API.exportTableRejectedToExcel().then((response) => {
      setLoadData(false);
      handleRejectedDownload(response?.data);
      if (response?.status === 200) {
        toast.success(
          "Data will be exported for last week only. For exporting data beyond that, please reach out to the support team."
        );
      }
    });
  };

  function onPageChange(
    type,
    { page, sizePerPage, sortField, sortOrder, filters, data },
    from
  ) {
    if (type == "sort") {
      if (sortOrder === "asc") {
        data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return 1;
          } else if (b[sortField] > a[sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return -1;
          } else if (b[sortField] > a[sortField]) {
            return 1;
          }
          return 0;
        });
      }

      setLoadData(false);
    } else {
      if (from == `total`) {
        setCurrPageNumber(page);
        fetchQueueList(page, sizePerPage, filters);
        setSizePerPage(sizePerPage);
      } else if (from == `success`) {
        setCurrPageNumberSuccess(page);
        fetchSuccessList(page, sizePerPage, filters);
        setSizePerPageSuccess(sizePerPage);
      } else if (from == `attention`) {
        setCurrPageNumberAttention(page);
        fetchAttentionList(page, sizePerPage, filters);
        setSizePerPageAttention(sizePerPage);
      } else if (from == `deleted`) {
        setCurrPageNumberDeleted(page);
        fetchDeletedList(page, sizePerPage, filters);
        setSizePerPageDeleted(sizePerPage);
      }
    }
  }

  function totalPageChange(
    type,
    { page, sizePerPage, sortField, sortOrder, filters, data }
  ) {
    if (!resetFilterAllOrders) {
      setLoadData(true);
      const from = `total`;
      onPageChange(
        type,
        { page, sizePerPage, sortField, sortOrder, filters, data },
        from
      );
    }
  }

  function successPageChange(
    type,
    { page, sizePerPage, sortField, sortOrder, filters, data }
  ) {
    if (!resetFilterProcessOrders) {
      setLoadData(true);
      const from = `success`;
      onPageChange(
        type,
        { page, sizePerPage, sortField, sortOrder, filters, data },
        from
      );
    }
  }

  function attentionPageChange(
    type,
    { page, sizePerPage, sortField, sortOrder, filters, data }
  ) {
    if (!resetFilterNeedAttention) {
      setLoadData(true);
      const from = `attention`;

      onPageChange(
        type,
        { page, sizePerPage, sortField, sortOrder, filters, data },
        from
      );
    }
  }

  function deletedPageChange(
    type,
    { page, sizePerPage, sortField, sortOrder, filters, data }
  ) {
    if (!resetFilterRejected) {
      setLoadData(true);
      const from = `deleted`;
      onPageChange(
        type,
        { page, sizePerPage, sortField, sortOrder, filters, data },
        from
      );
    }
  }

  // Reference method of Toolkit i.e. added only for the Export Option
  // const MyExportCSV = (props) => {
  //   const handleClick = () => {
  //     props.onExport();
  //   };
  //   return (
  //     <div>
  //       <button className="btn btn-success" onClick={handleClick}>
  //         Export to CSV
  //       </button>
  //     </div>
  //   );
  // };

  const handleAllPODownload = (orderData) => {
    // flatten object like this {id: 1, title:'', category: ''};

    if (orderData?.length === 0 || orderData === undefined) {
      toast("Record is not available or something went wrong");

      return;
    }
    const allPORecordsFile =
      "All Orders Records" + new Date().toJSON().slice(0, 10) + ".csv";
    const rows = orderData.map((record) => ({
      status: record.status,
      poReference: record.poReference,
      poReceivedOn: record.poReceivedOn,
      customerEmail: record.customerEmail,
      shippingMethod: record.shippingMethod,
      source: record.source,
      estoreOrderId: record.estoreOrderId,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "allPORecords");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Status",
        "PO Reference",
        "PO Received",
        "Customer Email",
        "Shipping Method",
        "Source",
        "Order ID",
      ],
    ]);

    XLSX.writeFile(workbook, allPORecordsFile, { compression: true });
  };

  const handleSuccessDownload = (processedOrders) => {
    if (processedOrders?.length === 0 || processedOrders === undefined) {
      toast("Record is not available or something went wrong");

      return;
    }

    const successRecordsFile =
      "Processed Orders Records" + new Date().toJSON().slice(0, 10) + ".csv";
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = processedOrders.map((successrecord) => ({
      status: successrecord.status,
      poReference: successrecord.poReference,
      poReceivedOn: successrecord.poReceivedOn,
      customerEmail: successrecord.customerEmail,
      shippingMethod: successrecord.shippingMethod,
      source: successrecord.source,
      estoreOrderId: successrecord.estoreOrderId,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "successRecords");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Status",
        "PO Reference",
        "PO Received",
        "Customer Email",
        "Shipping Method",
        "Source",
        "Order ID",
      ],
    ]);

    XLSX.writeFile(workbook, successRecordsFile, { compression: true });
  };

  const handleNeedAttentionDownload = (needReviewData) => {
    if (needReviewData?.length === 0 || needReviewData === undefined) {
      toast("Record is not available or something went wrong");

      return;
    }
    const queueRecordsFile =
      "Need Review Records" + new Date().toJSON().slice(0, 10) + ".csv";
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = needReviewData.map((queuerecord) => ({
      status: queuerecord.status,
      poReference: queuerecord.poReference,
      poReceivedOn: queuerecord.poReceivedOn,
      customerEmail: queuerecord.customerEmail,
      shippingMethod: queuerecord.shippingMethod,
      source: queuerecord.source,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "needAttentionRecords");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Status",
        "PO Reference",
        "PO Received",
        "Customer Email",
        "Shipping Method",
        "Source",
      ],
    ]);

    XLSX.writeFile(workbook, queueRecordsFile, { compression: true });
  };

  const handleRejectedDownload = (rejectedData) => {
    if (rejectedData?.length === 0 || rejectedData === undefined) {
      toast("Record is not available or something went wrong");

      return;
    }
    const rejectedRecordsFile =
      "Rejected Records" + new Date().toJSON().slice(0, 10) + ".csv";
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = rejectedData.map((rejectedrecord) => ({
      status: rejectedrecord.status,
      poReference: rejectedrecord.poReference,
      poReceivedOn: rejectedrecord.poReceivedOn,
      customerEmail: rejectedrecord.customerEmail,
      shippingMethod: rejectedrecord.shippingMethod,
      source: rejectedrecord.source,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "rejectedRecords");

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Status",
        "PO Reference",
        "PO Received",
        "Customer Email",
        "Shipping Method",
        "Source",
      ],
    ]);

    XLSX.writeFile(workbook, rejectedRecordsFile, { compression: true });
  };

  // const refreshButton = ()=>{
  //   if(isActive.id === "divOne"){
  //     return <><img src=""</>
  //   }
  // }

  return (
    <>
      <div id="pcoded" className="pcodedc" data-testid="ppcoded">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <Spinner showSpinner={loadData} /> {/* Assign props value  */}
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="row cus-card-sec">
                    <div className="col-sm-3 col-md-6 col-xl-3">
                      <div
                        id="divOne"
                        onClick={() => {
                          updateOrderCountAndFetchList("divOne");
                          resetAllColumnFilter();
                          hideShowDiv("divOne");
                        }} // Pass the ID directly here
                        className={`card widget-card-1 cus-order-btn ${
                          isActive.id === "divOne" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }} // Add cursor: pointer style
                      >
                        <div className="card-block-small">
                          <i className="icofont icofont-pie-chart bg-c-blue card1-icon" />
                          <span className="text-c-blue f-w-600">
                            All Orders
                          </span>
                          <h4>{count?.totalorder_count}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-6 col-xl-3">
                      <div
                        id="divTwo"
                        onClick={() => {
                          updateOrderCountAndFetchList("divTwo");
                          resetAllColumnFilter();
                          hideShowDiv("divTwo");
                        }} // Pass the ID directly here
                        className={`card widget-card-1 cus-order-btn ${
                          isActive.id === "divTwo" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }} // Add cursor: pointer style
                      >
                        <div className="card-block-small">
                          <i className="icofont icofont-ui-home bg-c-green card1-icon" />
                          <span className="text-c-green f-w-600">
                            Processed Orders
                          </span>
                          <h4>{count?.successfulorder_count}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-6 col-xl-3">
                      <div
                        id="divThree"
                        onClick={() => {
                          updateOrderCountAndFetchList("divThree");
                          resetAllColumnFilter();
                          hideShowDiv("divThree");
                        }}
                        className={`card widget-card-1 cus-order-btn ${
                          isActive.id === "divThree" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }} // Add cursor: pointer style
                      >
                        <div className="card-block-small">
                          <i className="icofont icofont-warning-alt bg-c-pink card1-icon" />
                          <span className="text-c-pink f-w-600">
                            Need Review
                          </span>
                          <h4>{count?.failorder_count} </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-6 col-xl-3">
                      <div
                        id="divFour"
                        onClick={() => {
                          updateOrderCountAndFetchList("divFour");
                          resetAllColumnFilter();
                          hideShowDiv("divFour");
                        }}
                        className={`card widget-card-1 cus-order-btn ${
                          isActive.id === "divFour" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="card-block-small">
                          <i className="ti-trash bg-danger card1-icon" />
                          <span className="text-c-danger f-w-600">
                            Rejected
                          </span>
                          <h4>{count?.rejectedorder_count}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="card col-md-12 col-xl-12">
                      <div className="card-block table-border-style">
                        <div className="table-responsive">
                          <div
                            className={
                              isActive.id === "divOne"
                                ? `divOne table`
                                : "divOne d-none table"
                            }
                          >
                            <div className="card-header">
                              <h5>All Orders</h5>
                              <div className="refreshexport-btn-sec">
                                <button
                                  className="refresh-btn-click"
                                  onClick={() => {
                                    updateOrderCountAndFetchList('divOne');
                                    resetAllColumnFilter();
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={HoverListRefresh}
                                    alt="Refresh Orders"
                                    title="Refresh Orders"
                                  />
                                  {/* Refresh */}
                                </button>
                                <button
                                  className="export-btn"
                                  onClick={allPORecordsToExcel}
                                >
                                  Export
                                </button>
                              </div>
                            </div>
                            {count?.totalorder_count == 0 ? (
                              <div className="cus-no-record-found">
                                <h5
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  No record found
                                </h5>
                              </div>
                            ) : (
                              <div className="App common-rel-cls">
                                <ToolkitProvider // Toolkit is added only for the Export Option
                                  keyField="id"
                                  data={totalData}
                                  columns={allPoDataTableColumns}
                                  exportCSV={{ exportAll: true }}
                                >
                                  {(props) => (
                                    <div className="cus-bootstrap-tb">
                                      {/* <BootstrapTable { ...props.baseProps } /> */}
                                      {/* <hr /> */}
                                      {/* <MyExportCSV {...props.csvProps} />  */}
                                    </div>
                                  )}
                                </ToolkitProvider>
                                <BootstrapTable
                                  remote
                                  keyField="id"
                                  data={totalData}
                                  columns={allPoDataTableColumns}
                                  filter={filterFactory()}
                                  pagination={paginationFactory({
                                    page: currPageNumber,
                                    sizePerPage: sizePerPage,
                                    totalSize: totalDataPagination?.total_count,
                                    showTotal: true,
                                    sizePerPageList: [
                                      { text: "10", value: 10 },
                                      { text: "50", value: 50 },
                                      { text: "100", value: 100 },
                                      { text: "200", value: 200 },
                                    ],
                                  })}
                                  onTableChange={totalPageChange}
                                  // exportCSV={ { exportAll: true } }
                                />
                              </div>
                            )}
                          </div>

                          <div
                            className={
                              isActive.id === "divTwo"
                                ? `divTwo table`
                                : "divTwo d-none table"
                            }
                          >
                            <div className="card-header">
                              <h5>
                                Processed Orders
                                {/* <button className="export-btn" onClick={successRecordsToExcel}>Export</button> */}
                              </h5>
                              <div className="refreshexport-btn-sec">
                                <button
                                  className="refresh-btn-click"
                                  onClick={() => {
                                    updateOrderCountAndFetchList('divTwo');
                                    resetAllColumnFilter();
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={HoverListRefresh}
                                    alt="Refresh Orders"
                                    title="Refresh Orders"
                                  />
                                  {/* Refresh */}
                                </button>
                                <button
                                  className="export-btn"
                                  onClick={successRecordsToExcel}
                                >
                                  Export
                                </button>
                              </div>
                            </div>
                            {count?.successfulorder_count == 0 ? (
                              <div className="cus-no-record-found">
                                <h5
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  No record found
                                </h5>
                              </div>
                            ) : (
                              <div className="table cus-successful-orders common-rel-cls">
                                <BootstrapTable
                                  remote
                                  keyField="id"
                                  data={successData}
                                  columns={successPoTableColumns}
                                  filter={filterFactory()}
                                  // filterPosition="top"
                                  pagination={paginationFactory({
                                    page: currPageNumberSuccess,
                                    sizePerPage: sizePerPageSuccess,
                                    totalSize:
                                      successDataPagination?.total_count,
                                    showTotal: true,
                                    sizePerPageList: [
                                      { text: "10", value: 10 },
                                      { text: "50", value: 50 },
                                      { text: "100", value: 100 },
                                      { text: "200", value: 200 },
                                    ],
                                  })}
                                  onTableChange={successPageChange}
                                />
                              </div>
                            )}
                          </div>

                          <div
                            className={
                              isActive.id === "divThree"
                                ? `divThree table`
                                : "divTwo d-none table"
                            }
                          >
                            <div className="card-header">
                              <h5>Need Review </h5>
                              <div className="refreshexport-btn-sec">
                                <button
                                  className="refresh-btn-click"
                                  onClick={() => {
                                    updateOrderCountAndFetchList('divThree');
                                    resetAllColumnFilter();
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={HoverListRefresh}
                                    alt="Refresh Orders"
                                    title="Refresh Orders"
                                  />
                                  {/* Refresh */}
                                </button>
                                <button
                                  className="export-btn"
                                  onClick={needAttentionRecordsToExcel}
                                >
                                  Export
                                </button>
                              </div>
                            </div>
                            {count?.failorder_count == 0 ? (
                              <div className="cus-no-record-found">
                                <h5
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  No record found
                                </h5>
                              </div>
                            ) : (
                              <div className="table cus-need-attention-record common-rel-cls">
                                <BootstrapTable
                                  remote
                                  keyField="id"
                                  data={queueData}
                                  columns={needAttentionPoTableColumns}
                                  filter={filterFactory()}
                                  pagination={paginationFactory({
                                    page: currPageNumberAttention,
                                    sizePerPage: sizePerPageAttention,
                                    totalSize:
                                      attentionDataPagination?.total_count,
                                    showTotal: true,
                                    sizePerPageList: [
                                      { text: "10", value: 10 },
                                      { text: "50", value: 50 },
                                      { text: "100", value: 100 },
                                      { text: "200", value: 200 },
                                    ],
                                  })}
                                  onTableChange={attentionPageChange}
                                />
                              </div>
                            )}
                          </div>

                          <div
                            className={
                              isActive.id === "divFour"
                                ? `divFour table`
                                : "divFour d-none table"
                            }
                          >
                            <div className="card-header">
                              <h5>Rejected </h5>
                              <div className="refreshexport-btn-sec">
                                <button
                                  className="refresh-btn-click"
                                  onClick={() => {
                                    updateOrderCountAndFetchList('divFour');
                                    resetAllColumnFilter();
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={HoverListRefresh}
                                    alt="Refresh Orders"
                                    title="Refresh Orders"
                                  />
                                  {/* Refresh */}
                                </button>
                                <button
                                  className="export-btn"
                                  onClick={rejectedRecordsToExcel}
                                >
                                  Export
                                </button>
                              </div>
                            </div>
                            {count?.rejectedorder_count == 0 ? (
                              <div className="cus-no-record-found">
                                <h5
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  No record found
                                </h5>
                              </div>
                            ) : (
                              <div className="table cus-deleted-record common-rel-cls">
                                <BootstrapTable
                                  remote
                                  keyField="id"
                                  data={deletedData}
                                  columns={rejectedPoTableColumns}
                                  filter={filterFactory()}
                                  pagination={paginationFactory({
                                    page: currPageNumberDeleted,
                                    sizePerPage: sizePerPageDeleted,
                                    totalSize:
                                      deletedDataPagination?.total_count,
                                    showTotal: true,
                                    sizePerPageList: [
                                      { text: "10", value: 10 },
                                      { text: "50", value: 50 },
                                      { text: "100", value: 100 },
                                      { text: "200", value: 200 },
                                    ],
                                  })}
                                  onTableChange={deletedPageChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {showListPopup && (
                  <Modal2
                    handleClose={hideModal}
                    id={id}
                    orderNotesPopup={orderNotesPopup}
                    setShowListPopup={setShowListPopup}
                  />
                )}
              </div>
              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Alert</Modal.Title>
          <button type="button" className="close" onClick={handleClose}>
            <span>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          Something went wrong. try refreshing the page or Please login again.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
              handleLogout();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Content;

const Modal2 = ({ handleClose, orderNotesPopup }) => {
  return (
    <div className="modal display-block">
      <section className="modal-main">
        <div className="notes-list-popup-sec">
          <div className="notes-list-popup-header-sec">
            <div>
              <h3>PO Notes</h3>
            </div>
            <div>
              <button className="popup-close" onClick={handleClose}>
                x
              </button>
            </div>
          </div>

          <div className="cus-list-notes">
            <ul>
              {orderNotesPopup?.map((item) => {
                return (
                  <li>
                    <div className="notes-list">{item?.Notes}</div>
                    <div className="notes-list-footer">
                      <div className="notes-list-left-footer">
                        By: <span>{item?.CreatedBy}</span>
                      </div>
                      <div className="notes-list-right-footer">
                        On: <span>{item?.CreatedOn}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};
