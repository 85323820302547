import React from "react";
import { useEffect, useState } from "react";
import "../App.css";
import baseURLObj from "../Common/AppUrl";
import axios from "axios";

export default function Status() {
  // const [loading, setLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  // const [rebbitmqQueueDetail, setRebbitmqQueueDetail] = useState();

  useEffect(() => {
    // healthDetail();
    async function healthDetail() {
      try {
        const response = await axios.get(baseURLObj.baseUrl + '/rest/api/v1/health/index.php', {
          // headers: headers
        });
        if(response.data.status == 200){
          setStatusData(response.data.data); // Assuming the API response is an array of data objects
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    healthDetail();
  }, []);

  // const healthDetail = () => {
  //   debugger;
  //   setLoading(true);
  //   API.healthStatus().then((response) => {
  //     // if (response?.status == 200) {
  //       setStatusData(response);
  //       console.log("Healt Carddddddddddd", statusData);
  //     // }
  //   });
  //   setLoading(false);
  // };


  return (
    <>
      <div className="cus-status-table">
        <h5> Health Check </h5>
        <div className="" style={{ width: "100%" }}>
          <table className="cus-status-table-data">
            <thead>
              <tr className="cus-no-top-border">
                <th>NAME</th>
                <th>STATUS</th>
                <th>SUMMARY</th>
              </tr>
            </thead>
            <tbody>
              {statusData?.database && ( <tr>  
                <td>
                  <span>Database</span>{" "}
                  <small>SupplyLink Connectivity with databases</small>
                </td>
                <td>
                  {statusData?.database?.status == 200 && (<button className="okbtn">OK</button>)}
                  {statusData?.database?.status !== 200 && (<button className="failedbtn">FAILED</button>)}
                </td>
                <td>{statusData?.database?.message}</td>
              </tr> )}

              {statusData?.rebbitmq && ( <tr>  
                <td>
                <span>RabbitMQ</span>{" "}
                  <small>SupplyLink Connectivity with RabbitMQ</small>
                </td>
                <td>
                  {statusData?.rebbitmq?.status == 200 && (<button className="okbtn">OK</button>)}
                  {statusData?.rebbitmq?.status !== 200 && (<button className="failedbtn">FAILED</button>)}
                </td>
                <td>{statusData?.rebbitmq.message}</td>
              </tr> )}
              
              { statusData?.rebbitmq_queues?.map((item) => {
                return  (<tr>  
              <td>
                <span>RabbitMQ Queue({item?.queue_name}) Listener</span>{" "}
                <small>RabbitMQ queue({item?.queue_name}) listener status</small>
              </td>
              <td>
                {item?.status == 200 && (<button className="okbtn">OK</button>)}
                {item?.status !== 200 && (<button className="failedbtn">FAILED</button>)}
              </td>
              <td>{item?.message}</td>
            </tr>)
            }) }
             
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
