import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Navbar from "./Navbar";
import Header from "./Header";
import Content from "./Components/Content";
import Login from "./Login";
import Status from "./Components/Status";
import Home from "./Home";
import ViewOrder from "./Components/ViewOrder";
import { refreshToken } from "./tokenRefresh"; // Import the refreshToken function
import { useUserActivity } from "./userActivity"; // Import the useUserActivity hook
import "./App.css";
import PageNotFound from "./Components/PageNotFound";
import { ToastContainer } from "react-toastify";


const AuthenticatedApp = ({ onLogout }) => (
  <>
    <div id="pcoded" className="pcoded">
      <div className="pcoded-overlay-box" />
      <div className="pcoded-container navbar-wrapper">
        <Header onLogout={onLogout} />
        <div className="pcoded-main-container" >
          <div className="pcoded-wrapper">
            <Navbar />
            <Content onLogout={onLogout} />
          </div>
        </div>
      </div>
    </div>
  </>
);

const UserApp = ({ onLogout }) => (
  <>
    <div id="pcoded" className="pcoded">
      <div className="pcoded-overlay-box" />
      <div className="pcoded-container navbar-wrapper">
        <Header onLogout={onLogout} />
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <Navbar />
            <Home onLogout={onLogout} />
          </div>
        </div>
      </div>
    </div>
  </>
);

const ViewOrderApp = ({ onLogout }) => (
  <>
    <div id="pcoded" className="pcoded">
      <div className="pcoded-overlay-box" />
      <div className="pcoded-container navbar-wrapper">
        <Header onLogout={onLogout} />
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <Navbar />
            <ViewOrder onLogout={onLogout} />
          </div>
        </div>
      </div>
    </div>
  </>
);

const PageNotFoundApp = ({ onLogout }) => (
  <>
    <div id="pcoded" className="pcoded">
      <div className="pcoded-overlay-box" />
      <div className="pcoded-container navbar-wrapper">
        <Header onLogout={onLogout} />
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <Navbar />
            <PageNotFound/>
          </div>
        </div>
      </div>
    </div>
  </>
);


const StatusApp = ({ onLogout }) => (
  <>
    <div id="pcoded" className="pcoded">
      <div className="pcoded-overlay-box" />
      <div className="pcoded-container navbar-wrapper status-pg">
      <Header onLogout={onLogout} />
        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">            
            <Status />
          </div>
        </div>
      </div>
    </div>
  </>
);

const UnauthenticatedApp = ({ onLogin }) => <Login onLogin={onLogin} />;

function App() {

  useEffect(() => {
    document.title = "SupplyLink";
    const handleFocus = () => {
      checkSessionTimeout(); // Trigger session check when window gains focus
    };

    window.addEventListener("focus", handleFocus);

    let checkSessionTimeout = () => {
      let loginTime = localStorage.getItem("loginTime");
      if (loginTime) {
        let elapsedTime = Date.now() - parseInt(loginTime);
        if (elapsedTime > 4 * 60 * 60 * 1000) {
          // User session expired, log out the user
          localStorage.removeItem("loginTime");
          handleLogout();
        }
      }
    };

    let interval = setInterval(checkSessionTimeout, 60000); // Check every minute

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

   const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("loginTime", Date.now());

  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    return <Navigate to="/login" />;
  };

   // Attach event listeners to track user activity
   window.addEventListener("mousemove", () => {
    // Update the last active time on mouse move
    localStorage.setItem("lastActiveTime", Date.now());
  });

  window.addEventListener("keypress", () => {
    // Update the last active time on keypress
    localStorage.setItem("lastActiveTime", Date.now());
  });

  const callApi = async () => {
    console.log("API called");
    refreshToken();
  };

  // Use the useUserActivity hook to handle user activity and token refresh
  useUserActivity(callApi);

  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              isLoggedIn ? (
                <AuthenticatedApp onLogout={handleLogout} />
              ) : (
                <UnauthenticatedApp onLogin={handleLogin} />
              )
            }
          />
          <Route
            exact
            path="/status"
            element={
              <StatusApp  />
              // isLoggedIn ? (
              //   <StatusApp className="status-with-login" onLogout={handleLogout} />
              // ) : (
              //   <StatusApp className="status-without-login" />
              // )
            }
          />
          <Route
            path="/home"
            element={
              isLoggedIn ? (
                <AuthenticatedApp onLogout={handleLogout} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/user"
            element={
              isLoggedIn ? (
                <UserApp onLogout={handleLogout} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route exact
            // path="/vieworder/:status/:id"
            path="/po/:id"
            element={
              isLoggedIn ? (
                <ViewOrderApp onLogout={handleLogout} />
              ) : (
                <Navigate to="/" />                
              )
            }
          />
          <Route
            path="*"
            element={
              isLoggedIn ? (
                <PageNotFoundApp onLogout={handleLogout} />
              ) : ""
            }
          />
        </Routes>
      </Router>
      <ToastContainer/>
    </div>
  );
}

export default App;
