// Redux Imports
import { createStore } from "redux";
import MainReducer from "./mainReducer";
// Library Imports
import { combineReducers } from "redux";

const appReducer = combineReducers({
  MainData: MainReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

// Create Store
const store = createStore(rootReducer);

// Store Export
export default store;
