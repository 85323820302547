import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios
import baseURLObj from "./Common/AppUrl";
import {env} from "./env";
import MainLogo from "./Assets/Images/logo.png";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "SupplyLink";
    // Check if user is already logged in, and redirect if necessary
    const isLoggedIn = localStorage.getItem("isUserIn");
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  // Useless code on Login page
  // const handleLogout = () => {
  //   localStorage.clear();
  //   navigate("/login");
  // };


  const handleLogin = async () => {
    setLoading(true);

    try {
      if (email && password) {
        // Step 1: Generate token with new  parameter password
        const tokenResponse = await axios.post(
          baseURLObj.baseUrl + "/rest/api/v1/admin/token/index.php",
          {
            user_login: email,
            user_password: password,
            api_key: env.REACT_APP_API_KEY,
            api_secret: env.REACT_APP_API_SECRET,
          }
        );

        if (tokenResponse && tokenResponse.data.status === 200) {
          const tokenExp = tokenResponse.data.exp_timestamp_utc; // Get the expiration time from the API response

          localStorage.setItem('token', tokenResponse.data.access_token);
          localStorage.setItem('tokenExp', JSON.stringify(tokenExp));
          localStorage.setItem('refreshToken', tokenResponse.data.refresh_token);
          const headers = {
            Authorization: "Bearer " + tokenResponse.data.access_token,
          };



          // Step 2: Authenticate with obtained token
          const response = await axios.post(
            baseURLObj.baseUrl + "/rest/api/v1/admin/login/index.php",
            {
              user_login: email,
              user_password: password,
            },
            {
              headers: headers,
            }
          );



          setLoading(false);
          if (response.data.status === 200) {
            localStorage.setItem("firstname", response.data.data.firstname);
            localStorage.setItem("lastname", response.data.data.lastname);
            localStorage.setItem("isUserIn", response.data.data.username);
            localStorage.setItem("user_id", response.data.data.id);
            localStorage.setItem("loginTime", Date.now());
            onLogin();
            navigate("/");
          } else {
            setError("Invalid email or password.");
          }
        }
      } else {
        setLoading(false);
        setError("Invalid email or password.");
      }
    } catch (error) {
      setLoading(false);
      // Step 2: Handle authentication error
      setError("Invalid email or password.");
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div>
      <section
        className="login p-fixed d-flex text-center"
        style={{ backgroundColor: "#f3f3f3" }}
      >
        {/* Container-fluid starts */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {/* Authentication card start */}
              <div className="login-card card-block auth-body mr-auto ml-auto">
                <form className="md-float-material" >
                  <div className="text-center">
                    <img className="logo-loginpg"
                      src={MainLogo}
                      alt="SupplyLink"
                      title="SupplyLink"
                      data-testid="cus-main-logo"
                    />
                  </div>
                  <div className="auth-box">
                    <div className="row m-b-20">
                      <div className="col-md-12">
                        <h3 className="text-left txt-primary">Sign In</h3>
                      </div>
                    </div>
                    <hr />

                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <span className="md-line" />
                    </div>
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}                        
                        required
                        onKeyPress={handleKeyPress}
                      />
                      <span className="md-line" />
                    </div>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <div className="row m-t-30">
                      <div className="col-md-12">
                        <button
                          type="button"
                          // type="submit"
                          className={`btn btn-primary btn-md btn-block waves-effect text-center m-b-20 ${loading ? "disabled" : ""
                            }`}
                          onClick={handleLogin}
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Login"}
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <p className="text-inverse text-left">
                          <b>Copyright © 2023. All rights reserved.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
                {/* end of form */}
              </div>
              {/* Authentication card end */}
            </div>
            {/* end of col-sm-12 */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container-fluid */}
      </section>
    </div>
  );
};

export default Login;