import React, { useState } from "react";
import loading from "../Assets/Images/spinner-img.gif";


export default function Spinner(props){ 

    const spins = props.showSpinner;  
    
    if (spins) {        
        return (
        <div className="loading-sec"><img src={loading} /></div> 
        )      
    };
    
   
 
}

