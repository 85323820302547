import React from "react";
import { NavLink } from "react-router-dom";
import MenuIcon from "./Assets/Images/menu-icon.png";
import { useEffect, useState } from "react";
import { $ } from "react-jquery-plugin";
import { render } from "react-dom";
import AnnounceKit from 'announcekit-react';

export default function Navbar() {
  const [isActiveMenu, setActiveMenu] = useState("false");

  const ToggleClass = () => {
    setActiveMenu(!isActiveMenu);
    if ($(".pcoded-navbar div").hasClass("active")) { /// To add class in parent component
      $(".pcodedc").addClass("active");
    } else {
      $(".pcodedc").removeClass("active");
    }
  };

  return (
    <nav className="pcoded-navbar">
      <div className="sidebar_toggle">
        <button onClick={ToggleClass} alt="Menu" data-testid="menus" name="ToggleMenu">
          <img className="menu-icon" src={MenuIcon} />
        </button>
      </div>
      <div className={isActiveMenu ? "active" : null} data-testid="menus-list">
        <ul className="pcoded-item pcoded-left-item">
          <li>
            <NavLink exact to="/" activeClassName="active">
              <span className="pcoded-micon">
                <i className="ti-home" />
                <b>D</b>
              </span>
              <span className="pcoded-mtext" data-i18n="nav.dash.main">
                Orders
              </span>
              <span className="pcoded-mcaret" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/user" activeClassName="active">
              <span className="pcoded-micon">
                <i className="ti-layers" />
                <b>FC</b>
              </span>
              <span
                className="pcoded-mtext"
                data-i18n="nav.form-components.main"
              >
                Settings
              </span>
              <span className="pcoded-mcaret" />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

