import { useEffect, useState } from "react";

export function useUserActivity(callApi) {
  const [isActive, setIsActive] = useState(true);
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now());

  useEffect(() => {
    let activityTimer;
    let totalActiveTime = 0;

    const resetActivityTimer = () => {
      if (localStorage.getItem("isUserIn") != null) {
        clearTimeout(activityTimer);
        localStorage.setItem('loginTime', JSON.stringify(Date.now()))
        setIsActive(true);
        setLastActivityTimestamp(Date.now()); // Update the last activity timestamp
      }
    };

    // const isTokenActive = setInterval(() => {
    //   if (localStorage.getItem("isUserIn") == null) {
    //      totalActiveTime = 0;
    //      return false
    //    }
    //    let loginExpTime = parseInt(localStorage.getItem("tokenExp"));
    //    let totalTimeToExpire = (loginExpTime * 1000) - parseInt(Date.now());
    //    if(totalTimeToExpire <= 0){
    //      callApi();
    //      setTimeout(() => {
    //     window.location.reload()
    //      }, 2000);
    //    }
    //  }, 1000);

    // Attach event listeners to the track user activity
    window.addEventListener("mousemove", resetActivityTimer);
    window.addEventListener("keypress", resetActivityTimer);
    window.addEventListener("click", resetActivityTimer);
    // Timer to increment the total active time
    const activityIncrementTimer = setInterval(() => {
      if (localStorage.getItem("isUserIn") == null) {
        totalActiveTime = 0;
        return false
      }
      let loginTime = localStorage.getItem("loginTime");
      let loginExpTime = parseInt(localStorage.getItem("tokenExp"));
      let totalTimeToExpire = (loginExpTime * 1000) - parseInt(Date.now());
      console.log(totalTimeToExpire)
      if (loginTime) {
        if (parseInt(totalTimeToExpire) <= 15 * 60 * 1000) {
          callApi();
        }
      }
    }, 60 * 1000); // 1 minute
    // Clean up event listeners and timers when the component unmounts
    return () => {
      window.removeEventListener("mousemove", resetActivityTimer);
      window.removeEventListener("keypress", resetActivityTimer);
      window.removeEventListener("click", resetActivityTimer);
      clearInterval(activityIncrementTimer);
      // clearInterval(isTokenActive);
    };
  }, [isActive]);
}
