import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import MainLogo from "./Assets/Images/logo.png";
import ProfileIcon from "./Assets/Images/avatar.jpeg";
import Navbar from "./Navbar"
import AnnounceKit from 'announcekit-react';
const Header = ({ onLogout }) => {

  const handleLogout = () => {
    onLogout(); 

  };
  const isLoggedInUser = localStorage.getItem("isUserIn");
  const firstName = isLoggedInUser ? localStorage.getItem("firstname") : "";
  const lastName = isLoggedInUser ? localStorage.getItem("lastname") : "";



  return <>
    <nav className="navbar header-navbar pcoded-header">
      <div className="navbar-wrapper container-fluid">
        <div className="navbar-logo" >
          <Link to="/">
            <img className="img-fluid" src={MainLogo} alt="Theme-Logo" />
          </Link>
          <div className='announcekitcode'>
            <div className="announcekit-widget">Whats New?</div>
          </div>
        </div>

      
        <div className="navbar-container">
          {/* <ul className="nav-left">
            <li>
              <div className="sidebar_toggle"><a href="javascript:void(0)"><i className="ti-menu" /></a></div>
            </li>
          </ul> */}
          <ul className="nav-right">
            <li className="header-notification">
            </li>
            <li className="user-profile header-notification">
              <a href="javascript:void(0)">
                <img src={ProfileIcon} className="img-radius" alt="User-Profile-Image" />
                <span id="user-name">
                {firstName} &nbsp; 
                {lastName}
                </span>
                <i className="ti-angle-down" />
              </a>
              <ul className="show-notification profile-notification">
                <li>
                  <a onClick={handleLogout} href="/">
                    <i className="ti-layout-sidebar-left" /> Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </>
}


export default Header;