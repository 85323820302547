import React from 'react';

const PageNotFound = () => {
    return (	<div className='pcoded cus-pg-404'>
    
    <div className='page-wrapper'>
    <div className='page-body'>
        <div className='card col-md-12 col-xl-12'>
            <div className='card-header'>
            <h5>404 Error</h5>
            </div>
            <div className='card-block table-border-style'>
            <h5 style={{paddingLeft:"3px"}}>Oops, something went wrong.</h5>
            </div>
		
        </div>
	</div>
    </div></div>
    )
}

export default PageNotFound;
