import React, { useState, useEffect } from "react";
import axios from "axios";
import baseURLObj from "./Common/AppUrl";

const Home = ({ onLogout }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await axios.get(baseURLObj.baseUrl + '/rest/api/v1/admin/list/index.php', {
          headers: headers
        });
        setData(response.data.data); // Assuming the API response is an array of data objects
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div id="pcoded" className="pcodedc">
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-body">
                <div className="page-body">
                  {/* Table */}
                  <div className="card col-md-12 col-xl-12">
                    <div className="card-header">
                      <h5>Users</h5>
                    </div>
                    <div className="card-block table-border-style">
                      <div className="table-responsive">
                        <table className="table cus-user-tb">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Username</th>
                              <th>Email</th>
                              <th>Role</th>
                              {/* Add more attributes */}
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.firstname}</td>
                                  <td>{item.lastname}</td>
                                  <td>{item.username}</td>
                                  <td>{item.email}</td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <button className="btn btn-round bg-c-green status smll">
                                      <span className="st-btn">{item.role}</span>
                                    </button>
                                  </td>
                                  {/* Add more table cells for additional attributes */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Table end */}
                </div>
              </div>
            </div>
            <div id="styleSelector"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
