import axios from "axios";
import baseURLObj from "../Common/AppUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const viewOrderDetails = async (
  id,
  section = "queue",
  marketplace = "dentira",
  estore = "zest"
) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/orders/view/index.php?id=" + id,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    // data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const searchSKU = async (sku) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/searchsku/index.php?sku=" + sku,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateSKUandProduct = async (payload) => {
  let data = JSON.stringify({
    column_type: payload.column_type,
    type: payload.type,
    order_id: payload.order_id,
    data: {
      old_sku: payload.old_sku,
      sku: payload.sku,
      // name: payload.name,
    },
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/updateorder/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const shippingMethodDropdownList = async (shippingListData) => {
  let data = JSON.stringify({
    store_id: shippingListData.store_id,
    customer_group_id: shippingListData.customer_group_id,
    order_total: shippingListData.order_total,
    order_quantity: shippingListData.order_quantity,
    product_ids: shippingListData.product_ids,
    address: {
      country: shippingListData.country,
      region_id: shippingListData.region_id,
      city: "",
      zipcode: "",
    },
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/availableshipping/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    // data: data,
    data: shippingListData,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateShippingMethodVal = async (param) => {
  let data = JSON.stringify({
    type: "shipping",
    order_id: param.order_id,
    data: {
      cost: param.cost,
      title: param.title,
      code: param.code,
    },
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/updateorder/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const processOrder = async (processData) => {
  // let data = JSON.stringify({
  //   requestID: processData.requestID
  // });

  let config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/orders/reorder/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: processData,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      toast.error(error?.response?.data?.message);
    });
};

const reasonsDropdownList = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/getrejectreasons/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    // data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const managePORequest = async (managePyaLoad) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/manageorder/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: managePyaLoad,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response?.data?.message);
    });
};

const notesAgainstPO = async (notesData) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/ordernotes/add/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: notesData,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const exportTableAllPOToExcel = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      baseURLObj.baseUrl +
      "/rest/api/v1/orders/total/index.php?page=1&pageSize=1week",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const exportTableSuccessToExcel = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      baseURLObj.baseUrl +
      "/rest/api/v1/orders/success/index.php?page=1&pageSize=1week",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const exportTableNeedAttentionToExcel = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      baseURLObj.baseUrl +
      "/rest/api/v1/orders/queue/index.php?page=1&pageSize=1week",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const exportTableRejectedToExcel = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      baseURLObj.baseUrl +
      "/rest/api/v1/orders/rejected/index.php?page=1&pageSize=1week",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const healthStatus = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: baseURLObj.baseUrl + "/rest/api/v1/health/index.php",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    // data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const API = {
  viewOrderDetails,
  searchSKU,
  updateSKUandProduct,
  shippingMethodDropdownList,
  updateShippingMethodVal,
  reasonsDropdownList,
  processOrder,
  managePORequest,
  notesAgainstPO,
  exportTableAllPOToExcel,
  exportTableSuccessToExcel,
  exportTableNeedAttentionToExcel,
  exportTableRejectedToExcel,
  healthStatus,
};

export default API;
