import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import API from "../API/API";
import Spinner from "./Spinner";
// Importing toastify module
import { ToastContainer, toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "bootstrap";
import { Navigate, useNavigate } from "react-router-dom";

// toast.configure()

function ManagePO({ handleClose, id, setShow }) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("processedmanually");
  const [loadDataViewOrder, setloadDataViewOrder] = useState(false);

  const changeHandler = (e) => {
    setSelected(e.target.value);
  };
  // toast("Wow so easy!");
  // toast.configure("Testing toast message")
  const [rejectPoReasonList, setRejectPoReasonList] = useState([]);

  const [reasonSelected, setReasonSelected] = useState("");
  const [additionComments, setAdditionComments] = useState("");

  const [magentoOrderId, setMagentoOrderId] = useState("");

  const [manageList, setManageList] = useState("");

  useEffect(() => {
    reasonsDropdownListVal();
  }, []);
  const reasonsDropdownListVal = () => {
    API.reasonsDropdownList().then((response) => {
      setRejectPoReasonList(response?.data);
      // console.log(response.data);
      // setEditingEnabled(false);
    });
  };
  const handleChange = (e) => {
    setReasonSelected(e.target.value);
  };

  const handleManualProcessSubmit = () => {
    setloadDataViewOrder(true);
    const manageManualProcessPayLoad = {
      reason_id: 1,
      order_id: id,
      data: {
        magento_order_id: magentoOrderId,
        reject_comment: additionComments,
      },
      user_id : localStorage.getItem("user_id"),
      user_name : localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")
    };

    API.managePORequest(manageManualProcessPayLoad).then((response) => {
      console.log(response);
      // setShow(false)
      // toast(response.message)
      setloadDataViewOrder(false);
      if (response?.status === 200) {
        toast.success(response?.message);
        navigate(-1);
      }
    });
  };
  const handleSubmit = () => {
    setloadDataViewOrder(true);
    if (reasonSelected === "") {
      setloadDataViewOrder(false);
      toast.warn("Reason not Selected");
      return;
    }
    const managePyaLoad = {
      reason_id: reasonSelected,
      order_id: id,
      data: {
        reject_comment: additionComments,
      },
      user_id : localStorage.getItem("user_id"),
      user_name : localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")
    };
    API.managePORequest(managePyaLoad).then((response) => {
      // setManageList(response?.data);
      console.log(response);
      setloadDataViewOrder(false);
      setShow(false);
      // toast('Hello managePORequest')
      // setEditingEnabled(false);
      if (response?.status === 200) {
        toast.success(response?.message);
        navigate(-1);
      } else{
        setloadDataViewOrder(false);
      }
    });
  };

  return (
    <div className="App manage-po-popup">
      <Spinner showSpinner={loadDataViewOrder} />
      <h5 className="popup-heading">Manage PO</h5>
      <div className="action-group">
        <span style={{ marginRight: "30px", display: "inline-flex" }}>
          <input
            type="radio"
            value="processedmanually"
            id="processedmanually"
            checked={selected === "processedmanually"}
            onChange={changeHandler}
          />
          <label htmlFor="processedmanually">Processed Manually</label>
        </span>

        <span style={{ marginRight: "15px", display: "inline-flex" }}>
          <input
            type="radio"
            value="rejectpo"
            id="rejectpo"
            checked={selected === "rejectpo"}
            name="gender"
            onChange={changeHandler}
          />
          <label htmlFor="rejectpo">Reject PO</label>
        </span>
        <div
          style={{ marginTop: "20px" }}
          aria-hidden={selected !== "processedmanually" ? true : false}
        >
          <div className="processed-detail">
            <input
              style={{ padding: "3px 7px" }}
              placeholder="Enter Magento Order #"
              // value={magentoOrderId}
              onChange={(e) => setMagentoOrderId(e.target.value)}
            />
            <div className="form-group" style={{ marginTop: "1rem" }}>
              <textarea
                rows="4"
                cols="50"
                placeholder="Enter comment here..."
                value={additionComments}
                onChange={(e) => setAdditionComments(e.target.value)}
              ></textarea>
            </div>

            <div className="popup-btn-group">
              <button
                className="cancelbtn"
                onClick={handleClose}
                style={{ marginRight: "12px" }}
              >
                Cancel{" "}
              </button>
              <button
                className="actionbtn"
                // disabled={!magentoOrderId}
                onClick={handleManualProcessSubmit}
              >
                Submit{" "}
              </button>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "20px" }}
          aria-hidden={selected !== "rejectpo" ? true : false}
        >
          <div className="processed-detail">
            <select
              style={{ padding: "5px", width: "100%" }}
              name="reject-po"
              id="reject-po"
              onChange={handleChange}
              value={reasonSelected}
            >
              <option value="">Select Reason</option>
              {rejectPoReasonList?.map((item) => {
                // console.log(item)
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            <div className="form-group" style={{ marginTop: "1rem" }}>
              <textarea
                rows="4"
                cols="50"
                placeholder="Enter comment here..."
                value={additionComments}
                onChange={(e) => setAdditionComments(e.target.value)}
              ></textarea>
            </div>

            <div className="popup-btn-group">
              <button
                className="cancelbtn"
                style={{ marginRight: "12px" }}
                onClick={handleClose}
              >
                Cancel{" "}
              </button>
              <button className="actionbtn" onClick={handleSubmit}>
                Submit{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePO;
